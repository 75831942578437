import {
  Box,
  Button,
  Heading,
  Image,
  Text,
  useToast,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  Flex,
  HStack,
} from "@chakra-ui/react";
import axios from "../../../utils/api/axios";
import React, { useEffect, useState } from "react";
import Layout from "../../../modules/layout/layout";
import secrets from "../../../secrets";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { useLocation } from "react-router-dom";
import { theme } from "../../../styles/theme/base";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { formatDate, formatTime } from "../../portalys/newsevents";
import moment from "moment";
import twitterIcon from "../../../static/assets/images/twitter.svg";
import instagramIcon from "../../../static/assets/images/instagram.svg";
import linkedinIcon from "../../../static/assets/images/socialIcon/linkedin.svg";
import ReCAPTCHA from "react-google-recaptcha";
import i18n from 'i18next';
import translationEN from '../../portalys/locales/en.json'
import translationFR from '../../portalys/locales/fr.json';
import translationES from '../../portalys/locales/es.json';


i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  },
});

export default function Follow() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1030px)");
  const isDesktop = useMediaQuery("(max-width: 1305px)");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [contactForm, setContactForm] = useState(false);
  const params = useParams();
  const { id } = params;
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  const currentDate = moment().format("YYYY-MM-DD");
  const [lngParam, setLngParam] = useState(null);

  useEffect(() => {
    // Get the URL search parameters
    const searchParams = new URLSearchParams(window.location.search);
    
    // Get the value of the 'lng' parameter
    const lngValue = searchParams.get('lng');

    // Update the state with the 'lng' parameter value
    setLngParam(lngValue);
    i18n.changeLanguage(lngValue);

  }, []);
  const bgC = useColorModeValue("white.100", "black.400");
  const text = useColorModeValue("black.100", "white.100");
  const reset = () => {
    setMessage("");
    setContactForm(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
         "/api/sendMessage",
        { fullName:name, email, message, receiver: data?.user?.email },
        {
          headers: {
            "x-auth-token": _?.token,
          },
        }
      )
      .then((res) => {
        reset();
        toast({
          title: "Message sent",
          isClosable: true,
          duration: 4000,
          status: "success",
          position: "top-right",
        });
      })
      .catch(() => {
        toast({
          title: "Unable to send",
          isClosable: true,
          duration: 4000,
          status: "error",
          position: "top-right",
        });
      });
  };
  const handleRecaptchaChange = (value) => {
    // Set the recaptcha verification state
    setRecaptchaVerified(true);
  };

  const getData = async () => {
    let url;
    if (_?.userData?.userId)
    {
      url="/api/userData/" + id + '/'+_?.userData?.userId

    }
    else{
      url="/api/userData/" + id

    }
    await axios
      .get(url)
      .then((res) => {
        const data = res.data;
        setData(data);
      })
      .catch((err) => {});
  };

  const follow = async () => {
    console.log()
    if (_?.token) {
      await axios
        .post("/api/follow/" + data?.user?.opId,  {
          headers: {
            "x-auth-token": _?.token,
          },
        })
        .then((res) => {
          getData();
          const data = res.data;
          toast({
            title: data?.message,
            isClosable: true,
            duration: 4000,
            status: data.status ? "success" : "error",
            position: "top-right",
          });
        })
        .catch((err) => {
          console.log({ err });
        });
    } else {
      toast({
        title: "Please Login",
        isClosable: true,
        duration: 4000,
        status: "error",
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      getData();
    }
  }, [id, _.token]);
  
  
  useEffect(() => {
    setName((_?.userData?.firstName||"")+" "+(_?.userData?.lastName||""));
    setEmail(_?.userData?.email||"");
   
  }, [_.token]);

  const location = useLocation();


  return (
    <Layout>
      <Modal
        isCentered={true}
        isOpen={contactForm}
        onClose={() => setContactForm(false)}
      >
        <ModalOverlay />
        <ModalContent  mx={0}
          color="white.100"
          borderRadius="2.5rem"
          boxShadow={
            isMobile
              ? "none"
              : "0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset"
          }
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalCloseButton />
          <ModalBody  display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            p={isMobile ? 15 : "60px 10px"}>
            <form onSubmit={handleSubmit}>
              <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  marginBottom={'10px'}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  marginBottom={'10px'}
                />
              </FormControl>
              <FormControl id="message" isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  marginBottom={"20px"}
                />
                            <ReCAPTCHA
    sitekey={secrets.recaptchaSiteKey} // Replace with your actual site key
    onChange={handleRecaptchaChange} // Add a function to handle recaptcha changes
    theme="light"
  />
              </FormControl>
              <Flex justifyContent="center">
                <Button type="submit" mt={4} disabled={!message || !name || !email|| !isRecaptchaVerified}>
                  Send
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box>
        <Box
          maxWidth={"76.125rem"}
          margin={isTablet ? "3.44rem auto" : "7.62rem auto"}
          padding={"0 1.25rem"}
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
          flexDir={isTablet ? "column" : "row"}
          gap={"1.87rem"}
          flexWrap={"wrap"}
        >
          <Box
            display={"flex"}
            justifyContent={isTablet ? "center" : "flex-start"}
            alignItems={"center"}
            flexDir={isTablet ? "column" : "row"}
            gap={"1.13rem"}
            flexWrap={"wrap"}
          >
            <Image
              src={data?.user?.image}
              width={isTablet ? "10.4375rem" : "11.1875rem"}
              height={isTablet ? "10.4375rem" : "11.1875rem"}
              borderRadius={"50%"}
              objectFit={"fill"}
              //bg={"#ffffff"}
            />
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={isTablet ? "center" : "flex-start"}
              gap={"0.56rem"}
            >
              <Text
                fontSize={isMobile ? "1.4375rem" : "2.375rem"}
                className="gordita600"
                lineHeight={"150%"}
                letterSpacing={"-0.02375rem"}
                textTransform={"capitalize"}
                textAlign={"start"}
              >
                {data?.user?.name || "Event Creator Name"}
              </Text>
              <Box
                display={"flex"}
                flexDir={isTablet ? "column-reverse" : "column"}
                justifyContent={"center"}
                alignItems={isTablet ? "center" : "flex-start"}
                gap={"1.25rem"}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDir={isMobile ? "column" : "row"}
                  gap={isMobile ? "0.63rem" : "1.25rem"}
                >
                  <Text
                    color={"rgba(255, 255, 255, 0.60)"}
                    fontSize={isMobile ? "1.1875rem" : "1.3125rem"}
                    className="gordita400"
                    lineHeight={"160%"}
                  >
                    {data?.user?.totalEvents}  {i18n.t("events")}
                  </Text>
                  <Text
                    color={"rgba(255, 255, 255, 0.60)"}
                    fontSize={isMobile ? "1.1875rem" : "1.3125rem"}
                    className="gordita400"
                    lineHeight={"160%"}
                  >
                    {data?.user?.totalFollowers}{" "}
                    {data?.user?.totalFollowers === 1
                      ? `${i18n.t("followers")}`
                      : ` ${i18n.t("followers")}`}
                  </Text>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"1rem"}
                >
                   {data?.user?.socialLinks?.twitter && (
                  <a
                    href={`https://twitter.com/${data?.user?.socialLinks?.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Image src={twitterIcon} width={"24px"} height={"24px"} />
                  </a>
                   )}
                    {data?.user?.socialLinks?.instagram && (
                  <a
                    href={`https://www.instagram.com/${data?.user?.socialLinks?.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Image src={instagramIcon} width={"24px"} height={"24px"} />
                  </a>
                    )}
                     {data?.user?.socialLinks?.linkedin && (
                  <a
                    href={`${data?.user?.socialLinks?.linkedin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Image src={linkedinIcon} width={"24px"} height={"24px"} />
                  </a>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            flexDir={isTablet ? "column-reverse" : "row"}
            gap={"1.25rem"}
            width={isTablet && "100%"}
            flexWrap={"wrap"}
          >
            <Button
              onClick={() => setContactForm(true)}
              sx={theme.buttons.secondary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
              _active={{
                bg: theme.buttons.secondary.pressed,
              }}
              style={{
                width: isTablet ? "100%" : "11.25rem",
                fontSize: "1rem",
              }}
              className="gordita700"
            >
               {i18n.t("contact")}
            </Button>
            <Button
              onClick={() => follow()}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: isTablet ? "100%" : "11.25rem",
                fontSize: "1rem",
              }}
              className="gordita700"
            >
               {data?.existingFollow?'Unfollow':i18n.t("follow")}
            </Button>
          </Box>
        </Box>

        <Box
          margin={isDesktop ? "4.06rem 1.25rem" : "7.5rem 0 5.31rem 7.81rem"}
        >
          <Box>
            <Text
              className="gordita600"
              fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01813rem"}
              textTransform={"capitalize"}
              marginBottom={"1.87rem"}
              textAlign={isMobile ? "center" : "start"}
            >
               {i18n.t("comingEvents")}
            </Text>

            {data?.events?.filter(
              (ticket) => ticket?.endDate >= currentDate
            ).length ? (
              <Box
                w="100%"
                display="flex"
                justifyContent={isMobile ? "center" : "flex-start"}
                alignItems="center"
                overflow={isMobile ? "unset" : "scroll"}
                gap={"0.62rem"}
                rowGap={isMobile ? "0.62rem" : "1.25rem"}
                columnGap={"1.25rem"}
                flexWrap={isMobile ? "wrap" : "unset"}
              >
              {data?.events
                  ?.filter((ticket) => ticket?.endDate >= currentDate)
                  .map((ticket, index) => {                  
                  let {
                    coverPicture: image,
                    eventName: heading,
                    organizer,
                    startDate: sdate,
                    startTime: time,
                    endTime: etime,
                    track,
                    categories,
                    city,
                    country,
                    uuid,
                    isLiked,
                    eventId,
                    slug,
                    timezone,
                  } = ticket;
                  const formattedDate = formatDate(sdate, timezone);
                  const formattedTime = formatTime(time);
                  const formattedETime = formatTime(etime);
                  return (
                    <Box
                      onClick={() => navigate("/event/" + slug)}
                      sx={theme.card.primary.shape}
                      key={ticket?.event?.idEvent}
                      css={{
                        height: "31.938rem",
                        padding: "0.62rem 0.62rem 1.56rem",
                        cursor: "pointer",
                        borderRadius: "1.5625rem",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pos={"relative"}
                        flex={"0 0 auto"}
                      >
                        <Image
                          w={"100%"}
                          h={"22.5rem"}
                          objectFit={"fit"}
                          borderRadius="0.9375rem"
                          src={image}
                        />
                      </Box>

                      <VStack
                        margin="1.25rem 0.62rem"
                        display="block"
                        gap={"0.62rem"}
                        overflow={"hidden"}
                      >
                        <Heading
                          color="#FBFAFB"
                          className="gordita700"
                          fontSize="1.4375rem"
                          textTransform={"capitalize"}
                          style={{
                            maxWidth: "22.5rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {heading}
                        </Heading>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text className="gordita600" whiteSpace={"nowrap"}>
                            {formattedDate}
                          </Text>
                          <Text whiteSpace={"nowrap"}>
                            {formattedTime + " - " + formattedETime}
                          </Text>
                        </HStack>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.user?.name || "Portalys"}
                          </Text>
                          <Text
                            color="rgba(255, 255, 255, 0.60)"
                            fontSize="1rem"
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {city || ""} {city && country ? ", " : ""}{" "}
                            {country || ""}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Text
                marginTop="4rem"
                fontSize="1.25rem"
                className="gordita600"
                fontWeight="700"
                textAlign={isMobile ? "center" : "start"}
                textTransform={"capitalize"}
              >
                No events yet
              </Text>
            )}
          </Box>
          <Box marginTop={isMobile ? "4.06rem" : "6.25rem"}>
            <Text
              className="gordita600"
              fontSize={isMobile ? "1.4375rem" : "1.8125rem"}
              lineHeight={"150%"}
              letterSpacing={"-0.01813rem"}
              textTransform={"capitalize"}
              textAlign={isMobile ? "center" : "start"}
              marginBottom={"1.87rem"}
            >
               {i18n.t("pastEvents")}
            </Text>

            {data?.events?.filter(
              (ticket) => ticket?.endDate < currentDate
            ).length ? (
              <Box
                w="100%"
                display="flex"
                justifyContent={isMobile ? "center" : "flex-start"}
                alignItems="center"
                overflow={isMobile ? "unset" : "scroll"}
                gap={"0.62rem"}
                rowGap={isMobile ? "0.62rem" : "1.25rem"}
                columnGap={"1.25rem"}
                flexWrap={isMobile ? "wrap" : "unset"}
              >
              {data?.events
                  ?.filter((ticket) => ticket?.endDate < currentDate)
                  .map((ticket, index) => {
                  let {
                    coverPicture: image,
                    eventName: heading,
                    organizer,
                    startDate: sdate,
                    startTime: time,
                    endTime: etime,
                    track,
                    categories,
                    city,
                    country,
                    uuid,
                    isLiked,
                    eventId,
                    slug,
                    timezone,
                  } = ticket;
                  const formattedDate = formatDate(sdate, timezone);
                  const formattedTime = formatTime(time);
                  const formattedETime = formatTime(etime);
                  return (
                    <Box
                      // onClick={() => navigate("/event/" + slug)}
                      sx={theme.card.primary.shape}
                      key={ticket?.event?.idEvent}
                      css={{
                        height: "31.938rem",
                        padding: "0.62rem 0.62rem 1.56rem",
                        cursor: "pointer",
                        borderRadius: "1.5625rem",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pos={"relative"}
                        flex={"0 0 auto"}
                      >
                        <Image
                          w={"100%"}
                          h={"22.5rem"}
                          objectFit={"fit"}
                          borderRadius="0.9375rem"
                          src={image}
                        />
                      </Box>

                      <VStack
                        margin="1.25rem 0.62rem"
                        display="block"
                        gap={"0.62rem"}
                        overflow={"hidden"}
                      >
                        <Heading
                          color="#FBFAFB"
                          className="gordita700"
                          fontSize="1.4375rem"
                          textTransform={"capitalize"}
                          style={{
                            maxWidth: "22.5rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {heading}
                        </Heading>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text className="gordita600" whiteSpace={"nowrap"}>
                            {formattedDate}
                          </Text>
                          <Text whiteSpace={"nowrap"}>
                            {formattedTime + " - " + formattedETime}
                          </Text>
                        </HStack>

                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          className="gordita400"
                        >
                          <Text
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.user?.name || "Portalys"}
                          </Text>
                          <Text
                            color="rgba(255, 255, 255, 0.60)"
                            fontSize="1rem"
                            style={{
                              width: "50%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {city || ""} {city && country ? ", " : ""}{" "}
                            {country || ""}
                          </Text>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Text
                fontSize="1.4375rem"
                fontWeight="700"
                className="gordita600"
                textTransform={"capitalize"}
                color={"rgba(255, 255, 255, 0.60)"}
                textAlign={isMobile ? "center" : "start"}
                marginTop={"1rem"}
              >
                No events yet.
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
