import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import axios from '../../../../utils/api/axios';
import { theme } from "../../../../styles/theme/base";
import CheckoutSMS from './CheckoutSMS';
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import secrets from "../../../../secrets";
import { loadStripe } from "@stripe/stripe-js";

function BlastConfirmationModal({ isOpen, onClose, uuids, type, onConfirm, message, internalName, organizerId }) {
  const [loading, setLoading] = useState(true);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [rate, setRate] = useState(0);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const stripePromise = loadStripe(secrets.stripeKey);


  const appearance = {
    theme: "night",
    variables: {
      colorPrimary: "#FFFFFF",
      colorBackground: "#141414",
      colorText: "#FFFFFF",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      // See all possible variables below
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    async function fetchPreview() {
      try {
        setLoading(true);
        const response = await axios.post('/api/previewBlastConfirmation', {
          uuids,
          type,
          message: message,
          internalName: internalName,
          organizerId: organizerId,
        });


        if (response.status === 200 && response.data.success) {
          setTotalRecipients(response.data.totalRecipients);
          setTotalPrice(response.data.totalPrice);
          setRate(response.data.rate);
          setError(null); // Clear any previous error
          setClientSecret(response.data.client_secret);
          setPaymentIntentId(response.data.paymentId);
          console.log(response.data);
        } else {
          setError('Failed to fetch preview data.');
        }
      } catch (err) {
        console.error('Error:', err);
        setError('Error fetching preview data.');
      } finally {
        setLoading(false);
      }
    }

    if (isOpen) {
      fetchPreview();
    }
  }, [isOpen, uuids, type]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #333333"
        borderColor="#333333"
        borderRadius="1.5rem"
        color="white"
        maxW="600px"
        minH="400px"
        p={6} // Increased padding for a more spacious feel
      >
        <ModalHeader fontSize="1.75rem" fontWeight="bold" borderBottom="1px solid #444" textAlign="center">
          Confirm {type} Blast
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={8} px={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center"> {/* Vertically aligned body */}
          {loading ? (
            <VStack spacing={5} align="center">
              <Spinner size="lg" color="white" />
              <Text fontSize="lg" textAlign="center">Loading preview data...</Text>
            </VStack>
          ) : error ? (
            <Box bg="red.700" p={4} borderRadius="0.5rem" textAlign="center">
              <Text color="white" fontWeight="bold">{error}</Text>
            </Box>
          ) : (
            <VStack align="center" spacing={6} textAlign="center">
              <Text fontSize="lg" fontWeight="bold">
                Total Unique {type === 'SMS' ? 'Phone Numbers' : 'Emails'}: <Text as="span" fontWeight="normal">{totalRecipients}</Text>
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                Price: <Text as="span" fontWeight="normal"> ${rate} * {totalRecipients} = ${totalPrice}</Text>
              </Text>
              <Text fontSize="lg" fontWeight="bold" color="yellow.400">
                Stripe Fees: <Text as="span" fontWeight="normal">${(Number(totalPrice) * 0.029 + 0.30).toFixed(2)}</Text> {/* Assuming 2.9% + $0.30 fee */}
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                Total Amount Charged: <Text as="span" fontWeight="normal">${(Number(totalPrice) + (Number(totalPrice) * 0.029 + 0.30)).toFixed(2)}</Text>
              </Text>
            </VStack>
          )}
          {clientSecret && paymentIntentId && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutSMS clientSecret={clientSecret} paymentIntentId={paymentIntentId} />
            </Elements>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BlastConfirmationModal;
