import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { theme } from "../../../../styles/theme/base";
import { Button, Flex, useToast, Link, Text, Box } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { useMediaQuery } from "../../../../utils/useMediaQuery";

const CheckoutSMS = () => {
  const isMobile = useMediaQuery("(max-width: 905px)");
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();

  useEffect(() => {
    if (stripe && elements) {
      setLoading(false);
    }
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (!error) {
      console.log("Payment confirmed successfully");
    } else {
      setErrorMessage(error.message);
    }
  };

  const desktopButtonStyle = {
    justifyContent: "center",
    mt: 4,
  };
  const mobileButtonStyle = {
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    height: "5.875rem",
    width: "100vw",
    backdropFilter: "blur(150px)",
    pos: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 1.25rem",
    zIndex: 10,
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: "100%",
        marginBottom: isMobile ? "3rem" : "0rem",
      }}
    >
      <Box
        border={"1px solid rgba(255, 255, 255, 0.20)"}
        borderRadius={"1.5625rem"}
        padding={"1rem"}
      >
        {loading ? (
          <Text>Loading payment options...</Text>
        ) : (
          <PaymentElement />
        )}

        <Flex {...(isMobile ? mobileButtonStyle : desktopButtonStyle)}>
          <Button
            disabled={!stripe || loading}
            type="submit"
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            style={{
              width: "100%",
            }}
          >
            Pay
          </Button>
        </Flex>
      </Box>
      <Text className="gordita400" marginTop={"0.94rem"} fontSize={"0.75rem"} marginBottom={"1.2rem"}>
        Pay securely with Stripe. By proceeding, you agree to our
        <Link className={"gordita700"} color="#FFFFFF" href="https://portalysio.notion.site/Legal-Compliance-b4922535aa5c463a81621aa533066195" target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </Link>
      </Text>

      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutSMS;
