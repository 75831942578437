import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  VStack,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Th,
  Td,
  TableContainer,
  Text,
  Image,
  useColorModeValue,
  Tr,
  useToast,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  Heading,
  Input,
  Circle,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { useEffect, useState,useRef, useCallback } from "react";
import {
  grid,
  list,
  location as locImage,
  refund,
} from "../../../static/assets/images/dashboard/sidebar";
import cancel from "../../../static/assets/images/TypeClose.svg";
import edit from "../../../static/assets/images/TypeEdit.svg";
import codeIcon from "../../../static/assets/images/codeIcon.svg";
import analytics from "../../../static/assets/images/Line_up_light.svg";
import eye from "../../../static/assets/images/eye.png";
import typeAddWhite from "../../../static/assets/images/dashboard/sidebar/TypeAddWhite.svg";
import trashicon from "../../../static/assets/images/TypeDelete.svg";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../styles/theme/base";
import { getEvents } from "../../../utils/actions/event";
import { isEmpty } from "lodash";
import { DownOutlined } from "@ant-design/icons";
import { Button as ABT, Dropdown, Space } from "antd";
import axios from "../../../utils/api/axios";
import secrets from "../../../secrets";
import Pagination from "../../layout/dashboard/pagination";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { debounce } from 'lodash';


const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};

/**
 * The function is a React component that displays a table or list of events, allows for event
 * cancellation and contract deployment, and includes pagination and filtering options.
 * @returns The `EventsComponent` functional component is being returned.
 */
const EventsComponent = () => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const handleCloseDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1020px)");
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const colorValue = useColorModeValue("black.100", "gray.100");
  const [displayType, setDisplayType] = useState("table");
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState({});
  const [_, setUser] = useRecoilState(user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventId, setEventId] = useState();
  const organizerId = _.userData?.organizerId;
  const [isBlackTheme, setIsBlackTheme] = useState(true);
  const embedContainerRef = useRef(null);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [collaborators, setCollaborators] = useState([]);

  const [isCollaboratorModalOpen, setIsCollaboratorModalOpen] = useState(false);
  const [collaboratorEmail, setCollaboratorEmail] = useState("");

  // Debounce the input change handler
  const handleInputChange = useCallback(
    debounce((value) => {
      setCollaboratorEmail(value);
    }, 300), // Adjust the delay as needed
    []
  );

  const getStatusText = (eventDate, status, cancelled) => {
    if (cancelled === true) {
      return "Cancelled";
    }
    if (status === false) {
      return "Draft";
    }
    const currentTime = new Date();
    const [year, month, day] = eventDate?.split("-");
    const eventTime = new Date(year, month - 1, day); // Months are zero-based in JavaScript Date objects
    eventTime.setDate(eventTime.getDate() + 1);
    const isEventDone = currentTime > eventTime;
    return isEventDone ? "Done" : "Live";
  };
  const getStatusColor = (eventDate, status, cancelled) => {
    if (cancelled === true) {
      return "#FF1B1B";
    }
    if (status === false) {
      return "rgba(255, 255, 255, 0.60)";
    }
    const currentTime = new Date();
    const [year, month, day] = eventDate?.split("-");
    const eventTime = new Date(year, month - 1, day); // Months are zero-based in JavaScript Date objects
    eventTime.setDate(eventTime.getDate() + 1);
    const isEventDone = currentTime > eventTime;
    return isEventDone ? "#FBF100" : "#02F6AD";
  };

  /**
   * The function retrieves events data from an API based on the user's role and stores it in local
   * storage.
   */
  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      const token = _?.token;
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        const val = await getEvents(organizerId);
        if (!isEmpty(val)) {
          const newEvents = [];
          Object.values(val).forEach((et) => {
            newEvents.push({ ...et });
          });
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const cancelEvent = async () => {
    const user = _?.userData;
    const token = _?.token;
    await axios
      .delete("/api/cancelEvent/" + eventId, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      })
      .then(async (res) => {
        await init();
        onClose();

        toast({
          title: "Event Canceled",
          position: "bottom",
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };
  const handleCopyCode = async (codeToCopy) => {
    try {
      // Attempt to copy the code to the clipboard
      await navigator.clipboard.writeText(codeToCopy);
      toast({
        title: "Code Copied",
        status: "success",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error copying code:", error);
      toast({
        title: "Copy Error",
        description: "Failed to copy the code. Please try again.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const handleEditClick = (eventuuid) => {
    navigate("/events/edit/" + eventuuid, { state: { isEditMode: true } });
  };
  const handleAnalyticsClick = (eventuuid) => {
    navigate("/events/analytics/" + eventuuid);
  };
  const handleCodeClick = (eventuuid) => {
    navigate("/events/embed/" + eventuuid);
  };
  
  const handleAddCollaborator = (uuid) => {
   axios.get("/api/get-event-collaborators/" + uuid, {
      headers: {
        "x-auth-token": "Bearer " +_.token,
      },
    }).then((res) => {
      setCollaborators(res.data.organizers);
    });
    setIsCollaboratorModalOpen(true);
  };
  const handleCollaboratorSubmit = async () => {
    setInviteLoading(true);
    await axios
      .post("/api/add-event-collaborator", {
        email: collaboratorEmail,
        uuid: eventId
      }, {
        headers: {
          "x-auth-token": "Bearer " +_.token,
        },
      })
      .then((res) => {
        init();
        toast({
          title: res?.data?.message,
          status: "success", 
          isClosable: true,
          duration: 4000,
          position: "bottom",
        });
        setInviteLoading(false);
        setIsCollaboratorModalOpen(false);
      })
      .catch((err) => {
        setInviteLoading(false);
          const url = window.location.origin + "/create-event?skip=true";
          const message = `Please make sure you are adding the correct organization email. If the collaborator doesn't have an organization yet, they can create one here:\n\n${url}\n\nClick OK to copy the link.`;
          if (window.confirm(message)) {
            navigator.clipboard.writeText(url);
            toast({
              title: "Link copied to clipboard",
              status: "success",
              duration: 2000,
              isClosable: true
            });
          }

      });
  };
  const handleRemoveCollaborator = async (collaboratorId) => {
    await axios.post("/api/remove-event-collaborator/" , {
      opId: collaboratorId,
      uuid: eventId
    }, {
      headers: {
        "x-auth-token": "Bearer " +_.token,
      },
    });
    setIsCollaboratorModalOpen(false);
    toast({
      title: "Collaborator Removed",
      status: "success",
      isClosable: true,
      duration: 4000,
      position: "bottom",
    });
  };

  const handleViewEvent = (eventslug) => {
    navigate("/event/" + eventslug);
  };

  const goToStripe = () => {
    const stripeConnectUrl = `https://stripe.com/`;
    window.location.href = stripeConnectUrl;
  };

  /**
   * The function deploys a contract by making an API call and updates the loading state accordingly,
   * while also displaying success or error messages using a toast notification.
   * @param id - The `id` parameter is a unique identifier for the contract that needs to be deployed.
   * It is used to construct the API endpoint URL for deploying the contract.
   */
  const deployContract = async (id) => {
    setLoading({ ...loading, [id]: true });
    await axios
      .get("/api/deployContract/" + id,{
        headers: {
          "x-auth-token": "Bearer " +_.token,
        },
      })
      .then(() => {
        init();
        toast({
          title: "Event Published on Marketplace",
          status: "success",
          isClosable: true,
          duration: 4000,
          position: "bottom",
        });
        setLoading({ ...loading, [id]: false });
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.message,
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "bottom",
        });
        setLoading({ ...loading, [id]: false });
      });
  };
  const deployPrivateContract = async (id) => {
    setLoading({ ...loading, [id]: true });
    await axios
      .get("/api/deployContractPrivate/" + id,{
        headers: {
          "x-auth-token": "Bearer " + _.token,
        },
      })
      .then(() => {
        init();
        toast({
          title: "Event Published Privately",
          status: "success",
          isClosable: true,
          duration: 4000,
          position: "bottom",
        });
        setLoading({ ...loading, [id]: false });
      })
      .catch((err) => {
        toast({
          title: err?.response?.data?.message,
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "bottom",
        });
        setLoading({ ...loading, [id]: false });
      });
  };


  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };
  /* The below code is a React component that renders a page for displaying a list of events. It
  includes a modal for confirming event cancellation, a datepicker and dropdown for filtering
  events, buttons for toggling between table and list view, creating new events, and
  editing/cancelling/refunding existing events. The events are displayed in either a table or a grid
  format, with information such as event name, date, start time, venue, status, and blockchain
  contract displayed for each event. Pagination is also included at the bottom of the page. */
  return (
    <Box
      w="100%"
      style={{
        fontFamily: "Gordita Regular",
        width: "100%",
        height: "100vh",
        overflow: "scroll",
        position: "relative",
      }}
    >
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          borderRadius={"20px"}
          border={"1px solid #333333"}
        >
          <ModalHeader color="white.100">Cancel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to cancel this event? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              sx={theme.buttons.secondary.shape}
              bg={theme.buttons.secondary.bgColor}
              color={theme.buttons.secondary.color}
              style={{
                width: "fit-content",
              }}
              _hover={{
                borderColor: theme.buttons.secondary.hoverBorderColor,
              }}
            >
              Cancel
            </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              style={{
                width: "5rem",
                padding: "0",
              }}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={cancelEvent}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCollaboratorModalOpen} onClose={() => setIsCollaboratorModalOpen(false)} isCentered={true}>
        <ModalOverlay />
        <ModalContent
        bg={"rgba(0, 0, 0, 0.50)"}
        backdropFilter={"blur(150px)"}
          borderRadius={"20px"}
          border={"1px solid #333333"}
        >
          <ModalHeader>Add Collaborator</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
            <Text fontWeight={"400"} fontSize={"1rem"} color={"#FFFFFF99"} mb={"1.25rem"}>
                  Add collaborators through their organization's email. Everyone in the organization will have access to the event.   
                </Text>
            <HStack spacing={4} width="100%">
              <Input
                placeholder="Enter collaborator's email"
                onChange={(e) => handleInputChange(e.target.value)}
              />
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                style={{
                  width: "6rem",
                }}
                onClick={handleCollaboratorSubmit}
                isLoading={inviteLoading}
              >
                Invite
              </Button>
            </HStack>
            {collaborators.length > 1 && (
              <>
                <Text fontWeight={"400"} fontSize={"1rem"} color={"#FFFFFF"} mb={"0.25rem"} mt={"1.25rem"}>
                  Collaborators:
                </Text>
                {collaborators.filter(collaborator => collaborator.opId !== _?.userData?.organizerId).map((collaborator) => (
                  <>
                   {_?.userData?.organizerId === collaborators[0].opId && (
                    <HStack spacing={2} alignItems="center" mb={2}>
                      <Button
                        cursor="pointer"
                        onClick={() => {
                          handleRemoveCollaborator(collaborator.opId);
                        }}
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        minW={0}
                        h="auto"
                        sx={theme.fixedButtons.secondary.shape}
                        bg={theme.buttons.secondary.default}
                        border="1px solid rgba(255, 255, 255, 0.20)"
                        backdropFilter="blur(100px)"
                      >
                        <Image alt="" src={trashicon} boxSize="16px" />
                      </Button>
                      <Text key={collaborator.email} fontWeight={"400"} fontSize={"1rem"} color={"#FFFFFF99"}>
                        {collaborator.name}: {collaborator.email}
                      </Text>
                      
                    </HStack>
                   )}
                   {!(_?.userData?.organizerId === collaborators[0].opId) && (
                    <Text key={collaborator.email} fontWeight={"400"} fontSize={"1rem"} color={"#FFFFFF99"} mb={"0.25rem"}>
                      {collaborator.name}: {collaborator.email}
                    </Text>
                   )}
                  </>
                ))}
              </>
            )}
            </>
            <ModalFooter/>
            
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box w="100%" minH="80vh">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          flexWrap={"wrap"}
          top={0}
          p={30}
          minHeight={"110px"}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          borderBottom={
            displayType === "table" ? "none" : "1px solid hsla(240, 1%, 59%, 1)"
          }
        >
          <Box fontSize={"32px"}>Events</Box>

          <Box display={"flex"} gap={"10px"}>
            {_?.userData?.userRole === 'ADMIN' && (
              <Button onClick={() => navigate('/resell-events')} marginRight="10px" bg="transparent">Resell Events</Button>
            )}
            {!isMobile && (
              <Box flex={1}>
                <Button
                  sx={theme.buttons.secondary.shape}
                  bg={theme.buttons.secondary.border}
                  color={theme.buttons.secondary.color}
                  border={theme.buttons.secondary.border}
                  _hover={{ bg: theme.buttons.tercary.hover, color: "black" }}
                  right={"0.75rem"}
                  onClick={() => {
                    if (displayType === "table") {
                      setDisplayType("list");
                    } else {
                      setDisplayType("table");
                    }
                  }}
                  style={{
                    height: "44px",
                  }}
                >
                  <Image src={displayType === "list" ? list : grid}></Image>
                </Button>
              </Box>
            )}
          
            <Button
              transition="all 0.2s ease-in-out"
              onClick={() => navigate("/events/create")}
              sx={theme.fixedButtons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              // style={{ width: "fit-content" }}
            >
              Create Event
            </Button>
          </Box>
        </Stack>
        <Box w="100%" px={"20px"} marginBottom={"30px"}>
          {displayType === "table" && !isTablet ? (
            <TableContainer>
              <Table
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0 18px",
                  marginBottom: "30px",
                }}
                variant="unstyled"
              >
                <Thead
                  borderBottom="1px solid"
                  borderColor="rgba(153, 159, 187,0.2)"
                >
                  <Tr>
                    {[
                      "Event Name",
                      "Date & Place",
                      "Status",
                      "Publish",
                      "Actions",
                    ].map((column, index) => (
                      <Th
                        key={index}
                        paddingBottom={"20px"}
                        textTransform="capitalize"
                        color="var(--surface-surface-secondary, #959597)" // Added color
                        fontFamily="Gordita" // Added font family
                        fontSize="1rem" // Added font size
                        fontStyle="normal"
                        fontWeight={400} // Added font weight
                        lineHeight="150%" // Added line height
                        letterSpacing="-0.01rem" // Added letter spacing
                        position="relative"
                        textAlign={"center"}
                        border={"none"}
                        borderRadius="0rem" // Added border radius
                        borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                        borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                        borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                        background="rgba(0, 0, 0, 0.50)" // Added background
                        backdropFilter="blur(75px)" // Added blur
                      >
                        {column}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody marginBottom={"30px"}>
                  {Object.values(data)?.map(
                    (
                      {
                        startDate,
                        status,
                        eventName,
                        startTime,
                        venue,
                        eventImage,
                        location,
                        transactionHash,
                        uuid,
                        endDate,
                        isPublished,
                        isCancelled,
                        slug,
                      },
                      index
                    ) => (
                      <Tr
                        overflow="hidden"
                        color="white.100"
                        key={index}
                        onClick={() => {
                          handleViewEvent(slug);
                        }}
                        cursor="pointer"
                      >
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Image w="57px" h="57px" alt="" src={eventImage} />
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {eventName || ""}
                            </Text>
                          </HStack>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text fontSize={14} className="gordita400" style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}>
                            {startDate || ""}, {location || ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            textAlign={"center"}
                            backgroundColor={getStatusColor(
                              endDate,
                              isPublished,
                              isCancelled
                            )}
                            color="#000000"
                            fontWeight={700}
                            fontSize={14}
                            borderRadius="25px"
                            className="gordita400"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            style={{
                              height: "38px",
                              width: "100px",
                            }}
                          >
                            {getStatusText(endDate, isPublished, isCancelled)}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          {!isPublished ? (
                            <Flex justifyContent="center" width={"100%"}>
                              <Menu placement="bottom">
                                <MenuButton
                                  as={Button}
                                  isLoading={loading[uuid]}
                                  disabled={loading[uuid]}
                                  sx={theme.buttons.primary.shape}
                                  bg={theme.buttons.primary.default}
                                  color={theme.buttons.primary.color}
                                  _hover={{
                                    bg: theme.buttons.primary.hover,
                                    boxShadow:
                                      theme.buttons.primary.hoverBoxShadow,
                                  }}
                                  _active={{
                                    boxShadow:
                                      theme.buttons.primary.activeBoxShadow,
                                  }}
                                  style={{
                                    height: "38px",
                                    width: "120px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  Publish <ChevronUpIcon />
                                </MenuButton>
                                <MenuList
                                  style={{
                                    backgroundColor: "black",
                                    borderRadius: "1.75rem", // 100px
                                    display: "flex", // Ensure that the MenuList behaves like a flex container
                                    justifyContent: "center", // Center the items horizontally
                                    flexDirection: "column", // Align items vertically
                                    alignItems: "center", // Align items horizontally
                                  }}
                                >
                                  <MenuItem
                                    sx={theme.buttons.primary.shape}
                                    bg={theme.buttons.primary.default}
                                    color={theme.buttons.primary.color}
                                    _hover={{
                                      bg: theme.buttons.primary.hover,
                                      boxShadow:
                                        theme.buttons.primary.hoverBoxShadow,
                                    }}
                                    _active={{
                                      boxShadow:
                                        theme.buttons.primary.activeBoxShadow,
                                    }}
                                    style={{
                                      height: "38px",
                                      width: "200px",
                                      cursor: "pointer",
                                      marginBottom: "8px",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deployContract(uuid);
                                    }}
                                  >
                                    Public
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      // Match the style of the MenuButton
                                      ...theme.buttons.primary.shape,
                                      bg: theme.buttons.secondary.default,
                                      color: theme.buttons.secondary.color,
                                      border: theme.buttons.secondary.border,
                                      _active: {
                                        boxShadow:
                                          theme.buttons.primary.activeBoxShadow,
                                      },
                                      _hover: {
                                        borderColor:
                                          theme.buttons.secondary
                                            .hoverBorderColor,
                                      },
                                      height: "38px",
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deployPrivateContract(uuid);
                                    }}
                                  >
                                    Private
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Flex>
                          ) : (
                            <Flex justifyContent="center" width={"100%"}>
                              <Menu placement="bottom">
                                <MenuButton
                                  as={Button}
                                  isLoading={loading[uuid]}
                                  disabled={loading[uuid]}
                                  py={2}
                                  border="1px solid #FFFFFF"
                                  bg={"black"}
                                  sx={theme.buttons.secondary.shape}
                                  style={{
                                    height: "38px",
                                    width: "120px",
                                  }}
                                  _hover={{}}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  Published <ChevronUpIcon />
                                </MenuButton>
                                <MenuList
                                  style={{
                                    backgroundColor: "black",
                                    borderRadius: "1.75rem", // 100px
                                    display: "flex", // Ensure that the MenuList behaves like a flex container
                                    justifyContent: "center", // Center the items horizontally
                                    flexDirection: "column", // Align items vertically
                                    alignItems: "center", // Align items horizontally
                                  }}
                                >
                                  <MenuItem
                                    sx={theme.buttons.primary.shape}
                                    bg={theme.buttons.primary.default}
                                    color={theme.buttons.primary.color}
                                    _hover={{
                                      bg: theme.buttons.primary.hover,
                                      boxShadow:
                                        theme.buttons.primary.hoverBoxShadow,
                                    }}
                                    _active={{
                                      boxShadow:
                                        theme.buttons.primary.activeBoxShadow,
                                    }}
                                    style={{
                                      height: "38px",
                                      width: "200px",
                                      cursor: "pointer",
                                      marginBottom: "8px",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deployContract(uuid);
                                    }}
                                  >
                                    Public
                                  </MenuItem>
                                  <MenuItem
                                    sx={{
                                      // Match the style of the MenuButton
                                      ...theme.buttons.primary.shape,
                                      bg: theme.buttons.secondary.default,
                                      color: theme.buttons.secondary.color,
                                      border: theme.buttons.secondary.border,
                                      _active: {
                                        boxShadow:
                                          theme.buttons.primary.activeBoxShadow,
                                      },
                                      _hover: {
                                        borderColor:
                                          theme.buttons.secondary
                                            .hoverBorderColor,
                                      },
                                      height: "38px",
                                      width: "200px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deployPrivateContract(uuid);
                                    }}
                                  >
                                    Private
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Flex>
                          )}
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          <Box
                            width={"100%"}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems="flex-end"
                            gap={5}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="6.25rem"
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              width="3.125rem"
                              height="3.125rem"
                              flexShrink={0} // Corrected syntax
                              onClick={(event) => {
                                event.stopPropagation();
                                setEventId(uuid);
                                onOpen();
                              }}
                            >
                              <Image alt="" src={cancel} />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="6.25rem"
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              width="3.125rem"
                              height="3.125rem"
                              flexShrink={0} // Corrected syntax
                              onClick={(event) => {
                                event.stopPropagation();
                                setEventId(uuid);
                                handleAddCollaborator(uuid);
                              }}
                              cursor="pointer"
                            >
                              <Image alt="" src={typeAddWhite} />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="6.25rem"
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              width="3.125rem"
                              height="3.125rem"
                              flexShrink={0} // Corrected syntax
                              onClick={(event) => {
                                event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                handleCodeClick(uuid);
                              }}
                              cursor="pointer"
                            >
                              <Image alt="" src={codeIcon} />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="6.25rem"
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              width="3.125rem"
                              height="3.125rem"
                              flexShrink={0} // Corrected syntax
                              onClick={(event) => {
                                event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                handleAnalyticsClick(uuid);
                              }}
                            >
                              <Image alt="" src={analytics} />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              borderRadius="6.25rem"
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              width="3.125rem"
                              height="3.125rem"
                              flexShrink={0} // Corrected syntax
                              onClick={(event) => {
                                event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                handleEditClick(uuid);
                              }}
                              cursor="pointer"
                            >
                              <Image alt="" src={edit} />
                            </Box>
                          </Box>
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Flex
              wrap="wrap"
              marginTop={"1.25rem"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"1.25rem"}
            >
              {data.map(
                (
                  {
                    startDate,
                    status,
                    eventName,
                    startTime,
                    venue,
                    eventImage,
                    location,
                    uuid,
                    endDate,
                    isPublished,
                    isCancelled,
                    slug
                  },
                  index
                ) => {
                  return (
                    <Box
                      // cursor="pointer"
                      key={index}
                      sx={theme.card.primary.shape}
                      transition="all 0.1s ease-in-out"
                      onClick={() => {
                        handleViewEvent(slug);
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pos={"relative"}
                        flex={"0 0 auto"}
                      >
                        <Box
                          flexShrink="0"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          pos={"absolute"}
                          left={"0.94rem"}
                          top={"0.94rem"}
                        >
                          <Box
                            px="30px"
                            py={2}
                            bg="#02F6AD"
                            fontSize={14}
                            w="max"
                            backgroundColor={getStatusColor(
                              endDate,
                              isPublished,
                              isCancelled
                            )}
                            borderRadius="6.25rem"
                            className="gordita600"
                            color="black.100"
                          >
                            {getStatusText(endDate, isPublished, isCancelled)}
                          </Box>
                        </Box>
                        <Image
                          w="100%"
                          h="26rem"
                          flexShrink={0}
                          borderRadius="var(--radius-radius-big, 1.25rem)"
                          src={eventImage}
                        />
                      </Box>

                      <VStack
                        gap={90}
                        padding="0 0.14rem"
                        display="block"
                        marginTop={"1.2rem"}
                      >
                        <Heading
                          color="#FBFAFB"
                          fontWeight="700"
                          fontSize="1.4375rem"
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {eventName}
                        </Heading>
                        <HStack
                          fontSize={{ base: 14, md: 16, "3xl": 16 }}
                          w="100%"
                          // pt={"0.45rem"}
                          className="gordita400"
                          color="#FFFFFF"
                        >
                          <Text>{startDate}</Text>
                          <Text> </Text>
                          <Text>{startTime}</Text>
                        </HStack>
                        <Text
                          // pt={"0.35rem"}
                          color="rgba(255, 255, 255, 0.60)"
                          fontSize="1rem"
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {location}
                        </Text>

                        <HStack
                          w="100%"
                          // pt={"0.74rem"}
                          className="gordita400"
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <HStack
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={"100%"}
                          >
                            {!isPublished ? (
                              <Flex justifyContent="start">
                                <Menu placement="top">
                                  <MenuButton
                                    as={Button}
                                    isLoading={loading[uuid]}
                                    disabled={loading[uuid]}
                                    sx={theme.buttons.primary.shape}
                                    bg={theme.buttons.primary.default}
                                    color={theme.buttons.primary.color}
                                    _hover={{
                                      bg: theme.buttons.primary.hover,
                                      boxShadow:
                                        theme.buttons.primary.hoverBoxShadow,
                                    }}
                                    _active={{
                                      boxShadow:
                                        theme.buttons.primary.activeBoxShadow,
                                    }}
                                    style={{
                                      height: "38px",
                                      width: "120px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    Publish <ChevronUpIcon />
                                  </MenuButton>
                                  <MenuList
                                    style={{
                                      backgroundColor: "black",
                                      borderRadius: "1.75rem", // 100px
                                      display: "flex", // Ensure that the MenuList behaves like a flex container
                                      justifyContent: "center", // Center the items horizontally
                                      flexDirection: "column", // Align items vertically
                                      alignItems: "center", // Align items horizontally
                                      zIndex: "100",
                                    }}
                                  >
                                    <MenuItem
                                      sx={theme.buttons.primary.shape}
                                      bg={theme.buttons.primary.default}
                                      color={theme.buttons.primary.color}
                                      _hover={{
                                        bg: theme.buttons.primary.hover,
                                        boxShadow:
                                          theme.buttons.primary.hoverBoxShadow,
                                      }}
                                      _active={{
                                        boxShadow:
                                          theme.buttons.primary.activeBoxShadow,
                                      }}
                                      style={{
                                        height: "38px",
                                        width: "200px",
                                        cursor: "pointer",
                                        marginBottom: "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deployContract(uuid);
                                      }}
                                    >
                                      Public
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        // Match the style of the MenuButton
                                        ...theme.buttons.primary.shape,
                                        bg: theme.buttons.secondary.default,
                                        color: theme.buttons.secondary.color,
                                        border: theme.buttons.secondary.border,
                                        _active: {
                                          boxShadow:
                                            theme.buttons.primary
                                              .activeBoxShadow,
                                        },
                                        _hover: {
                                          borderColor:
                                            theme.buttons.secondary
                                              .hoverBorderColor,
                                        },
                                        height: "38px",
                                        width: "200px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deployPrivateContract(uuid);
                                      }}
                                    >
                                      Private
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Flex>
                            ) : (
                              <Flex justifyContent="start">
                                <Menu placement="top">
                                  <MenuButton
                                    as={Button}
                                    isLoading={loading[uuid]}
                                    disabled={loading[uuid]}
                                    py={2}
                                    border="1px solid #FFFFFF"
                                    bg={"black"}
                                    sx={theme.buttons.secondary.shape}
                                    style={{
                                      height: "38px",
                                      width: "120px",
                                    }}
                                    _hover={{}}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    Published <ChevronUpIcon />
                                  </MenuButton>
                                  <MenuList
                                    style={{
                                      backgroundColor: "black",
                                      borderRadius: "1.75rem", // 100px
                                      display: "flex", // Ensure that the MenuList behaves like a flex container
                                      justifyContent: "center", // Center the items horizontally
                                      flexDirection: "column", // Align items vertically
                                      alignItems: "center", // Align items horizontally
                                    }}
                                  >
                                    <MenuItem
                                      sx={theme.buttons.primary.shape}
                                      bg={theme.buttons.primary.default}
                                      color={theme.buttons.primary.color}
                                      _hover={{
                                        bg: theme.buttons.primary.hover,
                                        boxShadow:
                                          theme.buttons.primary.hoverBoxShadow,
                                      }}
                                      _active={{
                                        boxShadow:
                                          theme.buttons.primary.activeBoxShadow,
                                      }}
                                      style={{
                                        height: "38px",
                                        width: "200px",
                                        cursor: "pointer",
                                        marginBottom: "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deployContract(uuid);
                                      }}
                                    >
                                      Public
                                    </MenuItem>
                                    <MenuItem
                                      sx={{
                                        // Match the style of the MenuButton
                                        ...theme.buttons.primary.shape,
                                        bg: theme.buttons.secondary.default,
                                        color: theme.buttons.secondary.color,
                                        border: theme.buttons.secondary.border,
                                        _active: {
                                          boxShadow:
                                            theme.buttons.primary
                                              .activeBoxShadow,
                                        },
                                        _hover: {
                                          borderColor:
                                            theme.buttons.secondary
                                              .hoverBorderColor,
                                        },
                                        height: "38px",
                                        width: "200px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deployPrivateContract(uuid);
                                      }}
                                    >
                                      Private
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Flex>
                            )}
                            <HStack gap={0} maxW="100%" overflowX="auto">
                              <Button
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{
                                  bg: theme.buttons.tercary.hoverBgColor,
                                }}
                                _active={{
                                  bg: theme.buttons.tercary.pressedBgColor,
                                }}
                                css={{
                                  width: "fit-content",
                                  padding: 0,
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEventId(uuid);
                                  handleAddCollaborator(uuid);
                                }}
                              >
                                <Image alt="" src={typeAddWhite} />
                              </Button>
                              <Button
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{
                                  bg: theme.buttons.tercary.hoverBgColor,
                                }}
                                _active={{
                                  bg: theme.buttons.tercary.pressedBgColor,
                                }}
                                css={{
                                  width: "fit-content",
                                  padding: 0,
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                  handleAnalyticsClick(uuid);
                                }}
                              >
                                <Image alt="" src={analytics} />
                              </Button>
                              <Button
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{
                                  bg: theme.buttons.tercary.hoverBgColor,
                                }}
                                _active={{
                                  bg: theme.buttons.tercary.pressedBgColor,
                                }}
                                css={{
                                  width: "fit-content",
                                  padding: 0,
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                  handleEditClick(uuid);
                                }}
                              >
                                <Image alt="" src={edit} />
                              </Button>
                                                            <Button
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{
                                  bg: theme.buttons.tercary.hoverBgColor,
                                }}
                                _active={{
                                  bg: theme.buttons.tercary.pressedBgColor,
                                }}
                                disabled={
                                  getStatusText(
                                    endDate,
                                    isPublished,
                                    isCancelled
                                  ) === "Done"
                                }
                                css={{
                                  width: "fit-content",
                                  padding: 0,
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEventId(uuid);
                                  onOpen();
                                }}
                              >
                                <Image alt="" src={cancel} />
                              </Button>
                              <Button
                                bg={theme.buttons.tercary.bgColor}
                                color={theme.buttons.tercary.color}
                                _hover={{
                                  bg: theme.buttons.tercary.hoverBgColor,
                                }}
                                _active={{
                                  bg: theme.buttons.tercary.pressedBgColor,
                                }}
                                css={{
                                  width: "fit-content",
                                  padding: 0,
                                  borderRadius: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevents the event from bubbling up the DOM tree
                                  handleCodeClick(uuid);
                                }}
                              >
                                <Image alt="" src={codeIcon} />
                              </Button>
                            </HStack>
                          </HStack>
                        </HStack>
                      </VStack>
                    </Box>
                  );
                }
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EventsComponent;
