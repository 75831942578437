import React from 'react';
import { Box, Text, HStack, Image, useToast, Button,VStack } from '@chakra-ui/react';
import AppStore from '../../static/assets/images/app-store.svg';
import GooglePlay from '../../static/assets/images/google-play.svg';
import { theme } from '../../styles/theme/base';
import info from '../../static/assets/images/ic_baseline-info.svg';
import backgroundMetal from '../../static/assets/images/background-metal.png';
import QRCode from "qrcode.react";
import exportedBackground from '../../static/assets/images/exported-background.jpg';
import secrets from '../../secrets';
import circoloco from '../../static/assets/images/434475853_409318645077735_7517070322655803479_n 1 (1).png';
import circoloMobileBg from '../../static/assets/images/cirocloco-mobile-bg.png';
import circoloDesktopBg from '../../static/assets/images/circoloco-desktop-bg.png';



export const DownloadAppComponent = ({ isTablet, isMobile }) => {
    const toast = useToast();

    const handleAppStoreClick = () => {
      window.open(secrets.iosLink, '_blank');
    };

    const handleGooglePlayClick = () => {
      window.open(secrets.androidLink, '_blank');
    };
    const handlePortalysDownloadClick = () => {
      window.open(secrets.portalysDownloadLink, '_blank');
    };

  return (
    <Box
      display="flex"
      width="100%"
      padding="2rem"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      gap="2rem"
      borderRadius="1.5rem"
      background="var(--White---8, rgba(255, 255, 255, 0.08))"
    >
      <Text
        alignSelf="stretch"
        color="#FFF"
        fontFamily="Gordita"
        fontSize="1.5rem"
        fontStyle="normal"
        fontWeight={700}
        lineHeight="132%"
        letterSpacing="-0.03rem"
      >
        Download the app to buy or access your tickets faster!
      </Text>

      <Text
        color="var(--White---64, rgba(255, 255, 255, 0.64))"
        fontFamily="Gordita"
        fontSize="1rem"
        fontStyle="normal"
        fontWeight={700}
        lineHeight="148%"
        alignSelf="stretch"
      >
        The easiest, most secure way to buy and access events.
      </Text>
{!isTablet ?(
      <HStack justifyContent="center" gap="20px">
        <Box onClick={handleAppStoreClick} cursor="pointer">
          <Image
            src={AppStore}
            style={{
              width: "120px",
              height: "40px",
            }}
          />
        </Box>
        <Box onClick={handleGooglePlayClick} cursor="pointer">
          <Image
            src={GooglePlay}
            style={{
              width: "135px",
              height: "40px",
            }}
          />
        </Box>
      </HStack>
    ):(<Box display="flex" justifyContent="center" width="100%">
      <Button
        fontWeight="700"
        textAlign="center"
        margin={isMobile ? "0 20px" : "20px 0"}
        fontSize="1rem"
        borderRadius="40px"
        outline="none"
        sx={theme.buttons.primary.shape}
        bg={theme.buttons.primary.default}
        color={theme.buttons.primary.color}
        className="gordita700"
        css={{
          width: "100%",
        }}
        _hover={{
          bg: theme.buttons.primary.hover,
          boxShadow: theme.buttons.primary.hoverBoxShadow,
          cursor: "pointer",
        }}
        _active={{
          boxShadow: theme.buttons.primary.activeBoxShadow,
        }}
        _disabled={{
          bg: theme.buttons.primary.disabled,
          color: theme.buttons.primary.colorDisabled,
        }}
        onClick={handlePortalysDownloadClick}
      >
        Download Portalys App
      </Button>
    </Box>)}
    </Box>
  );
};
export const TicketsInAppComponent = () => {
  return (
    <Box
      display="flex"
      width="100%"
      padding="1.25rem"
      alignItems="center"
      gap="1rem"
      borderRadius="1rem"
      background="rgba(255, 255, 255, 0.08)"
    >
      <HStack gap={1}>
        <Image src={info} alt="Info icon" />
        <Text>Access your tickets faster on the app</Text>
      </HStack>
    </Box>
  );
};

export const QRAppComponentDesktop = ({width}) => {
    const toast = useToast();

    const handleAppStoreClick = () => {
      window.open(secrets.iosLink, '_blank');
    };

    const handleGooglePlayClick = () => {
      window.open(secrets.androidLink, '_blank');
    };
    const handlePortalysDownloadClick = () => {
      window.open(secrets.portalysDownloadLink, '_blank');
    };
  return (
    <Box
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
         background={`url(${exportedBackground}) no-repeat center center`}
          backgroundSize="100% 100%"
          width={width}
          position="relative"
          overflow="visible" 
          height="100%" 
        >

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="1.5rem"
            position="relative"
            zIndex="1"
          >
            <Box
              display="flex"
              width="278px"
              height="278px"
              padding="2rem"
              alignItems="center"
              justifyContent="center"
              alignSelf="stretch"
              borderRadius="1.5rem"
              background="#FFF"
              overflow="hidden"
            >
              <QRCode
                value={secrets.portalysDownloadLink}
                size={246}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <HStack justifyContent="center" gap="20px">
              <Box onClick={handleAppStoreClick} cursor="pointer">
                <Image
                  src={AppStore}
                  style={{
                    width: "120px",
                    height: "40px",
                  }}
                />
              </Box>
              <Box onClick={handleGooglePlayClick} cursor="pointer">
                <Image
                  src={GooglePlay}
                  style={{
                    width: "135px",
                    height: "40px",
                  }}
                />
              </Box>
            </HStack>
          </Box>
        </Box>
  );
};

export const MarketPlaceComponent = ({ isTablet, isMobile, maxW, padding }) => {
  const toast = useToast(); 

  const handlePortalysDownloadClick = () => {
    window.open(secrets.portalysDownloadLink, '_blank');
  };

  return !isMobile ? (
    <Box
      display="flex"
      width="100%"
      maxW={maxW}
      height="37.5rem"
      padding={padding}
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="center"
      gap="2rem"
      borderRadius="1.5rem"
      border="1px solid rgba(255, 255, 255, 0.24)"
      background="#000"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="2.5rem"
        alignSelf="stretch"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="1rem"
          alignSelf="stretch"
        >
          <Text
            color="#FFF"
            textAlign="center"
            fontFamily="Gordita"
            fontSize="2rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="132%"
            letterSpacing="-0.04rem"
            alignSelf="stretch"
          >
            Get your tickets on the app!
          </Text>

          <Text
            color="rgba(255, 255, 255, 0.64)"
            fontFamily="Gordita"
            fontSize="1rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="148%"
            alignSelf="stretch"
          >
            The easiest, most secure way to buy and access events.
          </Text>
        </Box>
      </Box>
      <QRAppComponentDesktop width="100%" />
    </Box>
  ) : (
    <Box
      width="100%"
      padding="3rem 1.25rem"
      gap="2rem"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="1.5rem"
      border="1px solid rgba(255, 255, 255, 0.24)"
      overflow="hidden"
      backgroundImage={`url(${exportedBackground})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center bottom -175px"
      backgroundSize="100% auto"
      maxWidth={maxW}
    >
      <VStack marginTop="1rem" gap="2rem" width="100%" position="relative" zIndex="1">
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="2rem"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="132%"
          letterSpacing="-0.04rem"
          alignSelf="stretch"
        >
          Get your tickets on the app!
        </Text>
        
        <Text
          alignSelf="stretch"
          color="rgba(255, 255, 255, 0.64)"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="1rem"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="148%"
        >
         The easiest, most secure way to buy and access events.
        </Text>

        <Button
          fontWeight="700"
          textAlign="center"
          margin={isMobile ? "0 20px" : "20px 0"}
          fontSize="1rem"
          borderRadius="40px"
          outline="none"
          sx={theme.buttons.primary.shape}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          className="gordita700"
          css={{
            width: "100%",
          }}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
            cursor: "pointer",
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          _disabled={{
            bg: theme.buttons.primary.disabled,
            color: theme.buttons.primary.colorDisabled,
          }}
          onClick={handlePortalysDownloadClick}
        >
          Download Portalys App
        </Button>

      </VStack>
    </Box>
  );
};

export const MarketPlaceComponent2 = ({ isTablet, isMobile, maxW, padding,handlePortalysDownloadClick }) => {
  const toast = useToast(); 

  return !isMobile ? (
    <Box
      display="flex"
      width="1180px"
      maxW={maxW}
      height="37.5rem"
      padding={padding}
      paddingLeft="64px"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap="80px"
      borderRadius="1.5rem"
      border="1px solid rgba(255, 255, 255, 0.24)"
      background="#000"
      overflow="hidden"
      position="relative"
      backgroundImage={`url(${circoloDesktopBg})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center bottom -175px"
      backgroundSize="100% auto"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        gap="2.5rem"
        width="100%"
        padding="3rem"
        position="relative"
        zIndex="1"
      >
        <VStack spacing="1rem" alignItems="flex-start" width="100%">
          <Text
            color="#FFF"
            textAlign="left"
            fontFamily="Gordita"
            fontSize="2rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="132%"
            letterSpacing="-0.04rem"
          >
            Safely buy or sell tickets for Circoloco.
          </Text>

          <Text
            color="rgba(255, 255, 255, 0.64)"
            fontFamily="Gordita"
            fontSize="1rem"
            fontStyle="normal"
            fontWeight={700}
            lineHeight="148%"
          >
            With Resell get your tickets 100% guaranteed or you get your money back, no questions asked.
          </Text>
        </VStack>
        <Button
          fontWeight="700"
          textAlign="center"
          fontSize="1rem"
          borderRadius="40px"
          outline="none"
         // sx={theme.buttons.primary.shape}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          className="gordita700"
                    _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
            cursor: "pointer",
          }}
          _css={{
           width:"100%",
           height:"40px",
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          _disabled={{
            bg: theme.buttons.primary.disabled,
            color: theme.buttons.primary.colorDisabled,
          }}
          onClick={handlePortalysDownloadClick}
        >
          Find or sell your tickets
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50%"
        height="100%"
      >
        <Image
          src={circoloco}
          maxWidth="548px"
          maxHeight="392px"
          objectFit="contain"
        />
      </Box>
    </Box>
  ) : (
    <Box
      width="100%"
      padding="3rem 1.25rem"
      gap="2rem"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="1.5rem"
      border="1px solid rgba(255, 255, 255, 0.24)"
      overflow="hidden"
      maxWidth={maxW}
    >
      <Image
        src={circoloMobileBg}
        width="553px"
        height="532.276px"
        position="absolute"
        right="-169px"
        top="-156px"
      />
      <VStack marginTop="1rem" gap="2rem" width="100%" position="relative" zIndex="1">
        <Text
          color="#FFF"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="2rem"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="132%"
          letterSpacing="-0.04rem"
          alignSelf="stretch"
        >
          Safely buy or sell tickets for Circoloco.
        </Text>
        
        <Text
          alignSelf="stretch"
          color="rgba(255, 255, 255, 0.64)"
          textAlign="center"
          fontFamily="Gordita"
          fontSize="1rem"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="148%"
        >
         With Resell get your tickets 100% guaranteed or you get your money back, no questions asked.
        </Text>

        <Button
          fontWeight="700"
          textAlign="center"
          margin={isMobile ? "0 20px" : "20px 0"}
          fontSize="1rem"
          borderRadius="40px"
          outline="none"
          sx={theme.buttons.primary.shape}
          bg={theme.buttons.primary.default}
          color={theme.buttons.primary.color}
          className="gordita700"
          css={{
            width: "100%",
          }}
          _hover={{
            bg: theme.buttons.primary.hover,
            boxShadow: theme.buttons.primary.hoverBoxShadow,
            cursor: "pointer",
          }}
          _active={{
            boxShadow: theme.buttons.primary.activeBoxShadow,
          }}
          _disabled={{
            bg: theme.buttons.primary.disabled,
            color: theme.buttons.primary.colorDisabled,
          }}
          onClick={handlePortalysDownloadClick}
        >
          Find or sell your tickets
        </Button>

      </VStack>
    </Box>
  );
};