import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import axios from '../../../../utils/api/axios';
import { TrackingLinkDetails } from './index';
import { useParams } from 'react-router-dom';
import Layout from '../../../layout/layout';

const ShareAnalyticsLink = () => {
    const [visitData, setVisitData] = useState({}); // State to store visit data
    const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [eventName, setEventName] = useState(null);
  const params = useParams();
  const { eventSlug, linkId, promoterSlug } = params;

  const processData = (visitData) => {
    const deviceCount = {};
    const referrerCount = {};
    const geoLocationCount = {};
    let lastVisited = new Date(0); // Epoch time

    visitData.forEach((visit) => {
      deviceCount[visit.deviceInfo] = (deviceCount[visit.deviceInfo] || 0) + 1;
      referrerCount[visit.referrerUrl] =
        (referrerCount[visit.referrerUrl] || 0) + 1;
      geoLocationCount[visit.geolocation] =
        (geoLocationCount[visit.geolocation] || 0) + 1;
      const visitDate = new Date(visit.visitTimestamp);
      if (visitDate > lastVisited) {
        lastVisited = visitDate;
      }
    });

    const top5Devices = Object.entries(deviceCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const top5Referrers = Object.entries(referrerCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const top5GeoLocations = Object.entries(geoLocationCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);

    return {
      amountOfVisits: visitData.length,
      topDevices: top5Devices,
      topReferrers: top5Referrers,
      topGeoLocations: top5GeoLocations,
      lastVisited: lastVisited.toISOString(),
    };
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/getTrackingLinkVisits/${linkId}/${promoterSlug}`);
      const processedData = processData(response.data.visits);
      setVisitData((prevData) => ({
          ...prevData,
          [linkId]: processedData,
        }));
      console.log('rrrr',response.data);
      setNumberOfTickets(response.data?.link?.numberOfTickets);
      setTotalRevenue(response.data?.link?.totalRevenue);
      setEventName(response.data?.eventName);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {

    fetchData();
  }, [linkId]);

  if (!visitData) {
    return <Text>Loading...</Text>;
  }

  return (
    <Layout>
      <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" minHeight="100vh" mt="-100px" >
        <Box width="100%" marginX="30px">
          <Text fontSize="2xl" fontWeight="bold" marginBottom="40px">Analytics for promoter: {promoterSlug || "Unnamed Link"} | {eventName || "Unnamed event"}</Text>
          <TrackingLinkDetails
            numberOfTickets={numberOfTickets}
            totalRevenue={totalRevenue}
            visitData={visitData[linkId]}
            linkId={linkId}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default ShareAnalyticsLink;