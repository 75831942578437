import {
  Box,
  Button,
  HStack,
  Text,
  useToast,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Input,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  Stack,
  Image,
  Flex,

} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import { assignVerifier } from "../../../../utils/actions/registration";
import { theme } from "../../../../styles/theme/base";
import CountryFlag from "react-country-flag";
import { CopyIcon } from "@chakra-ui/icons";
import "../sharedAdminStyles.css";
import filtericon from "../../../../static/assets/images/ic_round-filter-list.png";
import {TypeAdd} from "../../../../static/assets/images/dashboard/sidebar";
import { v4 as uuidv4 } from 'uuid';
/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};

export const TrackingLinkDetails = ({ numberOfTickets, totalRevenue, visitData, linkId }) => (
  <>
    <Text mt={2}>
      Number of Tickets Sold: {numberOfTickets}
    </Text>
    <Text mt={2}>Total Revenue: {totalRevenue.toFixed(2)}</Text>
    <Text mt={2}>
      Conversion Rate:{" "}
      {visitData && visitData.amountOfVisits > 0
        ? ((numberOfTickets / visitData.amountOfVisits) * 100).toFixed(2) + "%"
        : "0%"}
    </Text>
    {visitData && (
      <>
        <Text mt={2}>
          Amount of Visits: {visitData.amountOfVisits}
        </Text>
        <Text mt={2}>
          Last Visited:{" "}
          {visitData.lastVisited
            ? new Date(visitData.lastVisited).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "No visits yet"}
        </Text>
        <Text mt={4}>
          Top 5 Devices:{" "}
          {visitData.topDevices?.map((device) => (
            <Text key={device[0]}>
              {device[0]} ({device[1]})
            </Text>
          ))}
        </Text>
        <Text mt={4}>
          Top 5 Referrers:{" "}
          {visitData.topReferrers?.map((referrer) => (
            <Text key={referrer[0]}>
              {referrer[0]} ({referrer[1]})
            </Text>
          ))}
        </Text>
        <Text mt={4}>
          Top 5 Locations:{" "}
          {visitData.topGeoLocations?.map((geo) => {
            const country = geo[0].split(", ").pop();
            return (
              <Text key={geo[0]}>
                <CountryFlag countryCode={country} svg />{" "}
                {geo[0]} ({geo[1]})
              </Text>
            );
          })}
        </Text>
      </>
    )}
  </>
);
const TrackingLinks = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [isAllEventsSelected, setIsAllEventsSelected] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState("");
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0]; // Format 'YYYY-MM-DD'
  const [selectedEvent, setSelectedEvent] = useState(""); // State for selected event
  const [promoterSlug, setPromoterSlug] = useState(""); // State for promoterSlug
  const [linkToDelete, setLinkToDelete] = useState(null);
  const [visitData, setVisitData] = useState({}); // State to store visit data
  const organizerId = _.userData?.organizerId;
  const [eventUuid, setEventUuid] = useState();
  const shouldShowContent = !location.pathname.includes("/share");
  const { isOpen:isOpen2, onOpen:onOpen2, onClose:onClose2 } = useDisclosure();
  const [shareableLink, setShareableLink] = useState("");


  const createTrackingLink = async () => {
    // Validation (ensure selectedEvent is not empty)
    if (!selectedEvent) {
      toast({
        title: "Missing Information",
        description: "Please select an event in order to create a promo link.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (promoterSlug && promoterSlug.length > 12) {
      toast({
        title: "Missing Information",
        description: "Promoter slug cannot exceed 12 characters.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // API call to create tracking link
    try {
      const response = await axios.post(
         "/api/createTrackingLink",
        {
          eventUuid: selectedEvent,
          promoterSlug: promoterSlug,
          promoterId: organizerId,
          organizerId: organizerId,
        },
        {
          headers: { "x-auth-token": "Bearer " + _?.token },
        }
      );

      // Handle successful response
      setIsModalOpen(false);
      getTrackingLinks();
      toast({
        title: "Tracking Link Created",
        description: "The tracking link has been successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle error
      toast({
        title: "Error",
        description: `Failed to create the tracking link. Please try again.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteTrackingLink = async () => {
    if (!linkToDelete) return;
    try {
      await axios.delete(
        `/api/deleteTrackingLink/${linkToDelete}`,
        {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        }
      );
      getTrackingLinks(); // Refresh the list after deletion
      onClose(); // Close the modal
      setLinkToDelete(null); // Reset the delete link ID
      toast({
        title: "Tracking Link Deleted",
        description: "The tracking link has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting tracking link:", error);
      toast({
        title: "Error",
        description: "Failed to delete the tracking link.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const requestDeleteTrackingLink = (linkId) => {
    setLinkToDelete(linkId);
    onOpen();
  };

  const fetchVisitData = async (linkId) => {
    try {
      // Make an API call to get the tracking link visits
      const response = await axios.get(
        `/api/getTrackingLinkVisits/${linkId}/`,
        {
          headers: {
            "x-auth-token": "Bearer " + _?.token,
          },
        }
      );

      // Process the visit data
      const processedData = processData(response.data.visits);

      // Update the visit data state with the processed data
      setVisitData((prevData) => ({
        ...prevData,
        [linkId]: processedData,
      }));

      // These notifications are just here for testing, remove them later
    } catch (error) {
      console.error("Error fetching visit data:", error);
    }
  };

  function fetchVisitDataOnOpen(index) {
    if (index >= 0 && data2[index]) {
      const linkId = data2[index].linkId;
      fetchVisitData(linkId);
    }
  }

  const processData = (visitData) => {
    const deviceCount = {};
    const referrerCount = {};
    const geoLocationCount = {};
    let lastVisited = new Date(0); // Epoch time

    visitData.forEach((visit) => {
      deviceCount[visit.deviceInfo] = (deviceCount[visit.deviceInfo] || 0) + 1;
      referrerCount[visit.referrerUrl] =
        (referrerCount[visit.referrerUrl] || 0) + 1;
      geoLocationCount[visit.geolocation] =
        (geoLocationCount[visit.geolocation] || 0) + 1;
      const visitDate = new Date(visit.visitTimestamp);
      if (visitDate > lastVisited) {
        lastVisited = visitDate;
      }
    });

    const top5Devices = Object.entries(deviceCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const top5Referrers = Object.entries(referrerCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const top5GeoLocations = Object.entries(geoLocationCount)
      .filter(([key, value]) => key != "null" && value != "null")
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);

    return {
      amountOfVisits: visitData.length,
      topDevices: top5Devices,
      topReferrers: top5Referrers,
      topGeoLocations: top5GeoLocations,
      lastVisited: lastVisited.toISOString(),
    };
  };

  const isEventsAvailable =
    data?.filter((event) => event?.endDate >= formattedCurrentDate).length !==
    0;

  const handleCheckboxToggle = (idEvent) => {
    setIsAllEventsSelected(false);
    setEvents((prevEvents) => {
      // Toggle the event ID in the events array
      if (prevEvents.includes(idEvent)) {
        return prevEvents.filter((event) => event !== idEvent);
      } else {
        return [...prevEvents, idEvent];
      }
    });
  };

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      if (user?.userRole === "ADMIN") {
        await axios
          .get("/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + _?.token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        console.log("checkpoint2");
        const val = await getEvents(organizerId);

        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object.values(val).forEach((et) => {
            newEvents.push({ ...et });
          });
          console.log("newevents2", newEvents);
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };


  const handleEditClick = async () => {
    try {
      const payload = {
        email: selectedEmail,
        events,
      };
      console.log("checkpoint30");
      const addVerifier = await assignVerifier(payload);
      console.log("checkpoint31");
      if (!addVerifier) {
        console.log("checkpoint32");
        toast({
          title: "Event Assigning Error",
          description: "Event already assigned",
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      } else {
        console.log("checkpoint33");
        toast({
          title: "Verifier Assigned Successfully",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        getTrackingLinks();
        setIsModalOpen2(false);
      }
      // Continue with your save logic...
    } catch (error) {
      setIsEmailValid(false); // Email is invalid
    }
  };

  const getTrackingLinks = async () => {
    try {
      let url = "/api/getTrackingLinks/" + organizerId;
      if (selectedEvent) {
        url += `?eventUuid=${selectedEvent}`; // Adding query parameter for event UUID
      }
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      });

      setData2(response.data?.links);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTrackingLinks();
  }, [_.userData?.userId, selectedEvent]); // Add selectedEvent as a dependency

  const handleCopyCode = async (codeToCopy) => {
    try {
      // Attempt to copy the code to the clipboard
      await navigator.clipboard.writeText(codeToCopy);
      toast({
        title: "Code Copied",
        status: "success",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error copying code:", error);
      toast({
        title: "Copy Error",
        description: "Failed to copy the code. Please try again.",
        status: "error",
        isClosable: true,
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const isAllEventsToggled =
    data
      ?.filter((event) => event?.endDate >= formattedCurrentDate)
      .map((event) => event?.uuid).length === events.length;

  return (
    <Box w="100%">
      {/* Additional UI for Creating Tracking Link */}
      <Box w="100%">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          flexWrap={"wrap"}
          top={0}
          p={30}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
        >
          <Box fontSize={"32px"} className="control-header-component-title">
            Marketing
          </Box>

          <Box
            sx={{
              display: "flex",
              maxWidth: "634px",
              padding: "5px",
              alignItems: "flex-start",
              borderRadius: "100px",
              border:
                "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
              background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
              backdropFilter: "blur(75px)",
            }}
            overflow={"scroll"}
            gap={"5px"}
            className="control-header-component-filters"
          >
            <Button
              onClick={() => {
                navigate("/attendees");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Attendees
            </Button>
            <Button
              onClick={() => {
                navigate("/guestlist");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Guest List
            </Button>
            <Button sx={selectedButton} minW={"150px"} p={"5px 25px"}>
              {" "}
              Tracking Links
            </Button>
            <Button
              onClick={() => {
                navigate("/promocodes");
              }}
              sx={notSelectedButton}
              minW={"150px"}
              p={"5px 25px"}
            >
              {" "}
              Promo Codes
            </Button>
          </Box>
          <Box
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.secondary.border}
            color={theme.buttons.secondary.color}
            border={theme.buttons.secondary.border}
            _hover={{ borderColor: theme.buttons.secondary.hoverBorderColor }}
            _active={{
              bg: theme.buttons.secondary.pressed,
            }}
            p="1"
            className="control-header-component-button"
          >
            <Image
              src={filtericon}
              width="1.5rem"
              height="1.5rem"
              borderRadius="var(--spacing-spacing-none, 0rem)"
            />
            <Select
  value={selectedEvent}
  border="none" // Added padding for better visual appearance
  onChange={(e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "All Events") {
      setSelectedEvent(null); // Set to null when "All Events" is selected
    } else {
      setSelectedEvent(selectedValue); // Otherwise, set the selected event
    }
  }}
  width="100%" // Adjusted width to fill the entire box
>
  <option value="All Events">All Events</option>
  {data?.map((event) => (
    <option key={event.uuid} value={event.uuid}>
      {event.eventName}
    </option>
  ))}
</Select>

          </Box>
          <Button
            onClick={handleButtonClick}
            sx={theme.fixedButtons.secondary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            className="control-header-component-button"
          >
            <img src={TypeAdd} alt="Add" />
          </Button>
        </Stack>
      </Box>
      <Box w="100%" minH="80vh">
        <Box w="100%" px={"30px"} marginBottom={"30px"}>
          <Accordion
            allowToggle
            onChange={(index) => fetchVisitDataOnOpen(index)}
          >
            {data2.map(
              ({
                linkId,
                promoterSlug,
                numberOfTickets,
                totalRevenue,
                eventSlug,
              }) => {
                const link =
                  secrets.clientUrl +
                  "/track/" +
                  eventSlug +
                  "/" +
                  promoterSlug;
                  const analyticsLink = secrets.clientUrl + "/track/analytics/share/" + eventSlug+'/'+linkId+'/'+promoterSlug;
                return (
                  <AccordionItem key={linkId}>
                    <AccordionButton>
                      <HStack flex="1" textAlign="left" alignItems="center">
                        <Box>
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            {promoterSlug || "Unnamed Link"}:
                          </span>{" "}
                          <span
                            style={{
                              fontcolor: "white",
                              textDecoration: "underline",
                            }}
                          >
                            {link}
                          </span>
                        </Box>
                        <IconButton
                          icon={<CopyIcon />}
                          aria-label="Copy Code"
                          onClick={() => handleCopyCode(link)}
                          // You can style the IconButton as needed
                          style={{
                            display: "flex",
                          }}
                        />
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} position="relative">
                      {!visitData[linkId] || visitData[linkId].length === 0 ? (
                        <Text>No visit data yet.</Text>
                      ) : (
                        <TrackingLinkDetails
                          numberOfTickets={numberOfTickets}
                          totalRevenue={totalRevenue}
                          visitData={visitData[linkId]}
                          linkId={linkId}
                        />
                      )}
                      <Box marginTop="20px">
                            {shouldShowContent && (
      <Flex justifyContent="center"                         position="absolute"
      right="60px"
      bottom="0px"
      m={4}
      >
        <Button
          sx={theme.buttons.secondary.shape}
          bg={theme.buttons.secondary.bgColor}
          color={theme.buttons.secondary.color}
          css={{
            width: "fit-content",
          }}
          _hover={{
            borderColor: theme.buttons.secondary.hoverBorderColor,
          }}
          _active={{
            bg: theme.buttons.secondary.pressed,
          }}
          border={theme.buttons.secondary.border}
          className="gordita700"
          onClick={() => {
            setShareableLink(analyticsLink);
            onOpen2();
          }}
        >
          Share Analytics
        </Button>
      </Flex>
      )}
                      {/* Delete Button positioned at bottom right */}
                      <IconButton
                        aria-label="Delete tracking link"
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        onClick={() => requestDeleteTrackingLink(linkId)}
                        position="absolute"
                        right="0"
                        bottom="0"
                        m={4}
                      />
                            </Box>

                      
                    </AccordionPanel>
                  </AccordionItem>
                );
              }
            )}
          </Accordion>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          borderRadius={"20px"}
          border={"1px solid #333333"}
        >
          <ModalHeader color="red.100">Delete Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this tracking link? All tracking
            data will also be deleted. This action cannot be undone!
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              style={{
                width: "fit-content",
              }}
              _hover={{ bg: theme.buttons.secondary.hover }}
            >
              Cancel
            </Button>
            <Button
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              style={{
                width: "5rem",
                padding: "0",
              }}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              onClick={deleteTrackingLink}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px solid #333333"
          borderColor="#333333"
          borderRadius="1.25rem"
        >
          <ModalHeader>Add Tracking Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack
              display={"flex"}
              alignItems={"start"}
              justifyContent={"center"}
              mt={"3rem"}
              gap={"50px"}
              width={"100%"}
            >
              <HStack spacing={4} width={"100%"}>
                <Select
                  w="33%"
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  <option value="">Select Event</option>
                  {data
                    .filter((event) => event?.endDate >= formattedCurrentDate)
                    .map((event) => (
                      <option key={event.uuid} value={event.uuid}>
                        {event.eventName}
                      </option>
                    ))}
                </Select>

                <Input
                  w="70%"
                  type="text"
                  placeholder="Enter Promoter Slug (Optional)"
                  value={promoterSlug}
                  onChange={(e) => {
                    const input = e.target.value;
                    const sanitizedInput = input
                      .toLowerCase()
                      .replace(/[^a-z0-9-]/g, ""); // Replace non-alphanumeric characters with '-'
                    setPromoterSlug(sanitizedInput);
                  }}
                />
              </HStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{ bg: theme.buttons.tercary.hover }}
              css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={createTrackingLink}
              sx={theme.buttons.primary.shape}
              bg={
                !isEventsAvailable
                  ? theme.buttons.primary.disabled
                  : theme.buttons.primary.default
              }
              color={
                !isEventsAvailable
                  ? theme.buttons.primary.colorDisabled
                  : theme.buttons.primary.color
              }
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
              disabled={!isEventsAvailable}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered={true}>
          <ModalOverlay />
          <ModalContent
            bg={"rgba(0, 0, 0, 0.50)"}
            backdropFilter={"blur(150px)"}
            borderRadius={"20px"}
            border={"1px solid #333333"}
          >
            <ModalHeader color="white.100">
              {" "}
              Share this tracking link's analytics
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              The shareable link updates in real-time. Anyone with the link can
              access this tracking link's analytics, so be cautious about who you send it to.
              <Box mt={4}>
                <Flex alignItems="center">
                  <Input
                    value={shareableLink}
                    readOnly
                    bg="gray.800"
                    border="1px solid #555555"
                    borderRadius="10px"
                    mr={2}
                    color="white.100"
                  />
                  <IconButton
                    icon={<CopyIcon />}
                    aria-label="Copy Code"
                    onClick={() => {
                      navigator.clipboard.writeText(shareableLink);
                    }}
                    // You can style the IconButton as needed
                    style={{
                      display: "flex",
                    }}
                  />
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.primary.default}
                color={theme.buttons.primary.color}
                style={{
                  width: "5rem",
                    padding: "0",
                  }}
                _hover={{
                  bg: theme.buttons.primary.hover,
                  boxShadow: theme.buttons.primary.hoverBoxShadow,
                }}
                _active={{
                  boxShadow: theme.buttons.primary.activeBoxShadow,
                }}
                onClick={onClose2}
              >
                OK
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </Box>
  );
};

export default TrackingLinks;
